<template>
  <div>
    <reseller-upload></reseller-upload>
  </div>
</template>

<script>
import ResellerUpload from "./../../components/resellers/upload";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("resellers.upload.title")
    };
  },
  components: {
    ResellerUpload
  }
};
</script>