<template>
  <div>
    <breadcrumb :items="breadcrumbs"></breadcrumb>
    <v-card
      color="rgba(0,0,0,0)"
      dark
      max-width="500"
      class="mx-auto py-0 mt-10"
    >
      <span class="text-h6 third--text">{{
        $t("resellers.upload.title")
      }}</span>
    </v-card>
    <v-card class="mx-auto mt-4" max-width="500" dark color="fifth">
      <v-progress-linear
        v-model="loading.upload"
        v-if="loading.save"
        color="third"
        height="20"
      >
        <template v-slot:default="{ value }">
          <strong class="black--text">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <v-divider></v-divider>
      <v-card-text class="px-5">
        <upload-file @file-changed="fileChanged" ref="upload"></upload-file>
        <div class="danger--text mt-3" v-if="error">
          <span class="font-weight-bold">{{ error }}</span>
        </div>
        <div
          class="d-flex align-center justify-end mt-5"
          :loading="loading.save"
        >
          <v-btn
            @click="upload"
            :loading="loading.save"
            color="secondary"
          >
            <span class="black--text">
              {{ $t("btn.upload") }}
            </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Breadcrumb from "../commons/breadcrumb.vue";
import { mapActions } from "vuex";
import UploadFile from "../commons/upload-file.vue";

export default {
  created() {
    this.setBreadcrumbs();
  },
  data: () => ({
    breadcrumbs: [],
    size: 5242880, // 5MB
    loading: {
      save: false,
      upload: 50,
    },
    error: null,
    uploaded: null,
  }),
  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("resellers.title"),
          to: this.$i18n.getRouteLink("reseller"),
          disabled: false,
          exact: true,
        },
        {
          text: this.$t("resellers.upload.title"),
          disabled: true,
        },
      ];
    },
    fileChanged() {
      this.error = null;
    },
    async upload() {
      if (!this.$refs.upload.isValid()) {
        this.notify({ message: this.$t("errors.bad_request") });
        return;
      }
      const file = this.$refs.upload.getFile();
      const data = new FormData();
      data.append("file", file);
      try {
        this.uploaded = 0;
        this.loading.upload = 0;
        this.loading.save = true;
        await this.request({
          url: "api/v1/resellers/upload",
          method: "POST",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          messages: {
            500: true,
            201: this.$t("resellers.upload.success"),
            200: this.$t("resellers.upload.success"),
            400: (errors) => {
              const error = this.$objectPath.get(
                errors,
                "errors.fields.file.0",
                null
              );
              this.error = error;
              return this.$t("errors.bad_request");
            },
          },
          onUploadProgress: (progressEvent) => {
            this.uploaded = this.$utils.humanFileSize(progressEvent.loaded);
            this.loading.upload = Math.floor(
              (progressEvent.loaded / this.$refs.upload.getFile().size) * 100 -
                5
            );
          },
        });
        this.loading.upload = 100;
        this.$refs.upload.resetFile();
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    file: {
      handler() {
        this.error = null;
      },
      deep: true,
    },
  },
  computed: {
    fileErrors() {
      const errors = [];

      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required &&
        errors.push(this.$t("resellers.fields.file.required"));

      !this.$v.file.size &&
        errors.push(
          this.$t("resellers.fields.file.invalidSize", {
            size: this.$utils.humanFileSize(this.size),
          })
        );

      !this.$v.file.extension &&
        errors.push(
          this.$t("resellers.fields.file.invalidFormat", {
            size: this.$utils.humanFileSize(this.size),
          })
        );

      return errors;
    },
  },
  components: {
    Breadcrumb,
    UploadFile,
  },
};
</script>